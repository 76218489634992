import React, { memo, useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import '../SideNav/SideNav.scss';

import DeepDiveSection from '../Chapter/DeepDiveSection';
import { hashManager } from '../../util';

function DeepDiveSideNav({ data }) {
  if (data?.length < 2) {
    return null;
  }

  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    hashManager.checkHashOnLoad();
  }, []);

  useEffect(() => {
    const domNodes = data
      .filter((link) => link.id)
      .map((link) => document.getElementById(link.id))
      .filter(Boolean);

    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach(function (entry) {
          if (entry.intersectionRatio > 0 && entry.isIntersecting) {
            setActiveSection(entry.target.id);
            hashManager.changeByScroll(entry.target.id);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      },
    );

    domNodes.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      domNodes.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, [data]);

  return (
    <aside className="SideNav DeepDiveSideNav">
      <ul>
        {data
          .filter((link) => link.id && link.title)
          .map((link) => (
            <li
              data-id={link.id}
              key={link.id}
              className={classnames({
                active: link.id.includes(activeSection),
              })}
            >
              <a href={`#${link.id}`} onClick={(e) => hashManager.changeByClick(e, link.id)}>
                {link.title}
              </a>
            </li>
          ))}
      </ul>
    </aside>
  );
}

DeepDiveSideNav.propTypes = checkExtraProps({
  data: PropTypes.arrayOf(DeepDiveSection.type.propTypes.data).isRequired,
});

DeepDiveSideNav.defaultProps = {};

export default memo(DeepDiveSideNav);
