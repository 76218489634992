import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { sanitizer } from '../../util';

function DeepDiveSection({ data: { id, precursor, title, section = [] } }) {
  return (
    <section id={id}>
      {precursor && precursor.enabled && <div dangerouslySetInnerHTML={{ __html: sanitizer(precursor.html) }} />}
      {title && <h2>{title}</h2>}
      {section?.map((data, index) => {
        return (
          <Fragment key={index}>
            {data.title && <h3>{data.title}</h3>}
            <div dangerouslySetInnerHTML={{ __html: sanitizer(data.content) }} />
          </Fragment>
        );
      })}
    </section>
  );
}

DeepDiveSection.propTypes = checkExtraProps({
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    precursor: PropTypes.shape({
      enabled: PropTypes.oneOf(['0', '1']),
      html: PropTypes.string,
    }),
    title: PropTypes.string.isRequired,
    section: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      }).isRequired,
    ).isRequired,
  }).isRequired,
});

DeepDiveSection.defaultProps = {};

export default memo(DeepDiveSection);
