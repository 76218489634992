import React, { memo, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import './Chapter.scss';

import { graphql } from 'gatsby';
import DeepDiveSection from './DeepDiveSection';
import DeepDiveSideNav from '../DeepDiveSideNav/DeepDiveSideNav';
import WhitepaperWrapper from '../WhitepaperWrapper/WhitepaperWrapper';

import { customPropTypes, useCSSMotion, useSectionTimeSpent } from '../../util';

function DeepDiveChapter({ data }) {
  const { sections, title, name, id } = data;

  const containerRef = useRef(null);
  const motionState = useCSSMotion(containerRef);

  useSectionTimeSpent(name, containerRef);

  return (
    <section id={id} className={classnames('Chapter DeepDive', motionState)} ref={containerRef}>
      <div className="background-layer" />
      <div className="title-container">
        <h1>{title}</h1>
      </div>
      <WhitepaperWrapper isDeepDive>
        {<DeepDiveSideNav data={sections} />}

        <article className="sections-wrapper blog-style">
          {sections?.map((sectionData) => (
            <DeepDiveSection key={sectionData.id} data={sectionData} />
          ))}
        </article>
      </WhitepaperWrapper>
    </section>
  );
}

DeepDiveChapter.propTypes = checkExtraProps({
  data: PropTypes.shape({
    ...customPropTypes.basePropTypes,
    title: PropTypes.string,
    sections: PropTypes.arrayOf(DeepDiveSection.type.propTypes.data).isRequired,
  }).isRequired,
});

DeepDiveChapter.defaultProps = {};

export default memo(DeepDiveChapter);

export const fragments = graphql`
  fragment DeepDiveChapter on WpPage_Flexiblelayout_FlexibleChildren_DeepDiveChapter {
    id
    jumpToLinkTitle
    name
    sections {
      id
      precursor {
        enabled
        html
      }
      section {
        content
        title
      }
      title
    }
    title
  }

  fragment DeepDiveChapter_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_DeepDiveChapter {
    id
    jumpToLinkTitle
    name
    sections {
      id
      precursor {
        enabled
        html
      }
      section {
        content
        title
      }
      title
    }
    title
  }
`;
